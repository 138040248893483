import React, { useState, useEffect } from 'react';
import { Twitter } from 'lucide-react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBGuJBV9PrgPYyhyBn3d47OEMKMff0lM_8",
  authDomain: "trumpcircle-9b1ef.firebaseapp.com",
  databaseURL: "https://trumpcircle-9b1ef-default-rtdb.firebaseio.com",
  projectId: "trumpcircle-9b1ef",
  storageBucket: "trumpcircle-9b1ef.firebasestorage.app",
  messagingSenderId: "358737835546",
  appId: "1:358737835546:web:1f48733be8b11c137043a3"
};

const BitcoinTracker = () => {
  const [marketCap, setMarketCap] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(200);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const marketCapRef = ref(database, 'marketCap');

    const unsubscribe = onValue(marketCapRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setMarketCap(data.value);
        setLastUpdated(data.lastUpdated);
      }
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching market cap:', error);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const baseSize = 200;
    const scale = Math.min(marketCap / 250000, 8);
    const targetSize = Math.min(baseSize * (1 + scale), window.innerHeight * 0.9);

    let animationFrame;
    let startTime = null;
    const duration = 500;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / duration;

      if (progress < 1) {
        const currentSize = size + (targetSize - size) * easeInOutCubic(progress);
        setSize(currentSize);
        animationFrame = requestAnimationFrame(animate);
      } else {
        setSize(targetSize);
      }
    };

    animationFrame = requestAnimationFrame(animate);

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [marketCap]);

  const easeInOutCubic = (x) => {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  return (
    <div className="min-h-screen bg-gray-900 relative">
      <div className="absolute top-4 right-4 flex gap-4 z-10">
        <a
          href="https://x.com/BitCircleToken"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors"
        >
          <Twitter size={20} />
          <span>Twitter</span>
        </a>
        <a
          href="https://pump.fun/7o6UwzA65W78ifHyd6v7veQP7t3EC2h8nYJiA7eqpump"
          target="_blank"
          rel="noopener noreferrer"
          className="px-4 py-2 bg-gradient-to-r from-orange-500 to-orange-600 text-white rounded-full hover:from-orange-600 hover:to-orange-700 transition-colors font-bold"
        >
          Buy
        </a>
      </div>

      <div className="flex items-center justify-center min-h-screen">
        {!isLoading && (
          <div className="relative">
            <div
              className="rounded-full relative bg-orange-500"
              style={{
                width: `${size}px`,
                height: `${size}px`,
                boxShadow: '0 0 50px rgba(249, 115, 22, 0.3)'
              }}
            >
              {/* Bitcoin Symbol */}
              <div className="absolute inset-0 flex items-center justify-center text-white font-bold"
                style={{
                  fontSize: `${size * 0.5}px`
                }}
              >
                ₿
              </div>

              {/* Market Cap Display */}
              <div className="absolute -bottom-16 left-0 right-0 text-center text-white">
                <div className="text-xl font-bold">
                  ${marketCap.toLocaleString(undefined, {maximumFractionDigits: 0})}
                </div>
                <div className="text-sm opacity-75">
                  Last updated: {formatLastUpdated(lastUpdated)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BitcoinTracker;
